import React from "react";
import { BsTablet } from "react-icons/bs";
import { PiCashRegister } from "react-icons/pi";
import { FiMonitor } from "react-icons/fi";
import { BiUnlink } from "react-icons/bi";
import { formatDateTime } from "../helpers/DateHelpers";

const CommonDeviceItem = ({ device, onUnlink }) => {
  return (
    <>
      <div className="border border-slate-300 px-4 py-2 flex flex-row justify-center items-center mb-3 rounded-xl">
        {device?.deviceType === "tablet" ? (
          <BsTablet size={20} />
        ) : device?.deviceType === "pos" ? (
          <PiCashRegister size={28} />
        ) : (
          <FiMonitor size={20} />
        )}

        <div className="ml-3 flex flex-col justify-start items-start">
          <h4 className="font-medium">{device?.deviceName ?? ""}</h4>
          <h4 className="font-medium text-sm mb-1">
            {device?.deviceType == "tablet"
              ? "Appetite OrderPad"
              : device?.deviceType == "pos"
              ? "Appetite Point of Sale"
              : "Kitchen Display"}
          </h4>
          <h4 className="font-medium text-sm mb-1">
            Connected:{" "}
            {formatDateTime(device?.connectedOn ?? new Date().toISOString())}
          </h4>
        </div>

        <div style={{ flex: 1 }} />

        <BiUnlink size={28} color="#FF4A49" onClick={() => onUnlink(device)} />
      </div>
    </>
  );
};

export default CommonDeviceItem;
