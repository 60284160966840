import React from "react";
import { useNavigate } from "react-router-dom";
import {
  getFormattedOrderStatus,
  getOrderColor,
  getOrderTextColor,
} from "../helpers/OrderHelpers";
import { toSentenceCase } from "../helpers/StringHelpers";
import { formatOrderDate, nowIsLater } from "../helpers/DateHelpers";

const CommonOrderItem = ({ order }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => navigate(`/order/${order?.id ?? ""}`)}
        style={{
          backgroundColor: getOrderColor(order?.status ?? "awaiting"),
          color: getOrderTextColor(order?.status ?? "awaiting"),
        }}
        className="mt-2 px-4 py-3 rounded-xl"
      >
        <p className="font-semibold text-lg">
          {toSentenceCase(order?.orderType ?? "")}
        </p>
        <p className="text-lg font-medium">
          {order?.customer?.firstName ?? ""} {order?.customer?.lastName ?? ""}
        </p>
        <p className="text-base font-normal">
          {getFormattedOrderStatus(order?.status ?? "awaiting")}
        </p>

        <p className="text-base font-normal">
          {nowIsLater(order?.orderTime)
            ? "ASAP"
            : formatOrderDate(order?.orderTime ?? new Date().toISOString())}
        </p>
      </div>
    </>
  );
};
export default CommonOrderItem;
