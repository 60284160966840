import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RestaurantContext } from "../../../data/contexts/RestaurantContext";
import { FaChevronLeft } from "react-icons/fa";
import DayDropdown from "../../../common/DayDropdown";
import FilledButton from "../../../common/FilledButton";
import TimeDropdown from "../../../common/TimeDropdown";
import Checkbox from "../../../common/Checkbox";

import { FaRegTrashCan } from "react-icons/fa6";
import { TbCirclePlus } from "react-icons/tb";
import APIController from "../../../data/APIController";
import {
  ADD_TRADING_HOURS,
  DELETE_TRADING_HOURS,
} from "../../../constants/ApiEndpoints";
import { showAPIError } from "../../../helpers/FieldHelpers";

const AddTradingHours = () => {
  const navigate = useNavigate();
  const { restaurant } = useContext(RestaurantContext);

  const [loading, setLoading] = useState(false);

  const [closed, setClosed] = useState(false);
  const [day, setDay] = useState(null);

  const [timeSlots, setTimeSlots] = useState([
    { openTime: null, closeTime: null },
  ]);

  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, { openTime: null, closeTime: null }]);
  };

  const handleRemoveTimeSlot = (index) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots.splice(index, 1);
    setTimeSlots(newTimeSlots);
  };

  const handleTimeChange = (index, field, value) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index][field] = value;
    setTimeSlots(newTimeSlots);
  };

  const addTradingHours = () => {
    if (!day) {
      showAPIError("Please select a day to apply these trading hours to.");
      return;
    }

    // Validate each timeslot to ensure both openTime and closeTime are filled
    const isAllTimeslotsValid = timeSlots.every(
      (slot) => slot.openTime && slot.closeTime
    );

    if (!isAllTimeslotsValid) {
      showAPIError("Please ensure all Open and Close Times are completed.");
      return;
    }

    APIController.post(ADD_TRADING_HOURS, {
      restaurantId: restaurant?.id ?? "",
      day: day,
      hours: timeSlots,
      closed: closed,
    })
      .then((response) => {
        console.log("ADD TRADING HOURS RESPONSE: ", JSON.stringify(response));

        if (!response.data?.success) {
          showAPIError(response.data?.message ?? "");
          return;
        }

        navigate("/settings/hours");
      })
      .catch((error) => {
        showAPIError(error?.message ?? "");
        console.log("Error");
      });
  };

  return (
    <>
      <div className="flex flex-row fixed top-0 pt-6 pb-4 px-6 bg-white w-screen">
        <FaChevronLeft
          size={20}
          onClick={() => {
            navigate(-1);
          }}
        />

        <div style={{ flex: 1 }} />
        <p className="text-base font-medium">Add Trading Time</p>
        <div style={{ flex: 1 }} />

        <FaChevronLeft size={20} color={"#ffffff"} />
      </div>

      <div className="px-6 mt-20 pb-4">
        <h4 className="font-medium text-lg">Add Trading Hours</h4>
        <p className="font-normal text-sm">
          Trading hours determine when your store will be open. If your store is
          closed, you must specify the day and that it is closed on that day,
          otherwise your store may open unexpectedly.
        </p>

        <div className="mt-4">
          <h4 className="font-medium">Day</h4>
          <DayDropdown onSelectDay={setDay} />
        </div>

        {timeSlots.map((slot, index) => (
          <div
            key={index}
            className="flex flex-row justify-center items-center mt-4"
          >
            <div className="w-1/2 pr-1">
              <h4 className="font-medium">Open Time</h4>
              <TimeDropdown
                value={slot.openTime}
                onSelectTime={(e) => handleTimeChange(index, "openTime", e)}
                disabled={closed}
              />
            </div>

            <div className="w-1/2 pl-1">
              <h4 className="font-medium">Close Time</h4>
              <TimeDropdown
                value={slot.closeTime}
                onSelectTime={(e) => handleTimeChange(index, "closeTime", e)}
                disabled={closed}
              />
            </div>

            {timeSlots.length > 1 && (
              <FaRegTrashCan
                color="#FF4A49"
                style={{ marginTop: 32, marginLeft: 8 }}
                size={22}
                onClick={() => handleRemoveTimeSlot(index)}
              />
            )}
          </div>
        ))}

        <div
          className="flex flex-row justify-start items-center mt-4"
          onClick={handleAddTimeSlot}
        >
          <TbCirclePlus size={28} />
          <p className="ml-2 font-medium">Add another timeslot</p>
        </div>

        <div className="flex flex-row mt-8">
          <Checkbox
            checked={closed}
            label={"Closed"}
            onChange={(value) => {
              console.log("Closed is: ", value);
              setClosed(value);
            }}
          />
        </div>
      </div>

      <div className="w-full px-6">
        <FilledButton text={"Add Trading Time"} onPress={addTradingHours} />
      </div>
    </>
  );
};

export default AddTradingHours;
