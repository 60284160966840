import { closePopup, openPopup } from "./ModalHelpers";

export const showAPIError = (value) => {
  openPopup({
    title: "Error Occurred",
    description: value,
    positiveButtonTap: () => {
      closePopup();
    },
    positiveButtonText: "Okay",
  });
};

export const validateUsername = (value) => {
  if (value.length <= 0) {
    openPopup({
      title: "Invalid Username",
      description: "Please enter a valid restaurant username",
      positiveButtonTap: () => {
        closePopup();
      },
      positiveButtonText: "Okay",
    });
    return false;
  } else {
    return true;
  }
};

export const validatePassword = (value) => {
  if (value.length <= 0) {
    openPopup({
      title: "Invalid Password",
      description: "Please enter a valid restaurant password",
      positiveButtonTap: () => {
        closePopup();
      },
      positiveButtonText: "Okay",
    });
    return false;
  } else {
    return true;
  }
};
