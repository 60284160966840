import React, { useState, useEffect } from "react";
import { Modal, ModalContent } from "@nextui-org/react";
import EventBus from "../../helpers/EventBus";
import FilledButton from "../FilledButton";

const OrderActionModal = ({
  isVisible,
  onClose,
  onDelay,
  onRefund,
  onCancel,
}) => {
  return (
    <Modal isOpen={isVisible} hideCloseButton={true} onOpenChange={onClose}>
      <ModalContent
        className="px-6 pt-3.5 pb-6"
        style={{
          position: "fixed", // Fixed positioning to overlay content
          top: "50%", // Position at the middle of the viewport vertically
          left: "50%", // Position at the middle of the viewport horizontally
          transform: "translate(-50%, -50%)", // Adjust position to perfectly center
          width: "90%", // Set width to 90% of the viewport to have some margin on the sides
          maxWidth: "600px", // Limit max-width to 600px so it doesn't get too wide
          margin: "0 auto", // Center the modal horizontally
          overlay: "auto",
          zIndex: 1000,
        }}
      >
        <h1 className="text-lg text-black mt-2 font-semibold">Order Actions</h1>
        <div className="mt-2 mb-2">
          <p className="text-base text-black font-normal mb-2">
            Select an option to take for this order
          </p>
        </div>

        {/* Delay Order */}
        <FilledButton
          text={"Delay Order"}
          backgroundColor={"#006692"}
          onPress={onDelay}
        />
        <div style={{ height: 8 }} />

        {/* Refund Specific Amount */}
        <FilledButton
          text={"Refund Specific Amount"}
          backgroundColor={"#710193"}
          onPress={onRefund}
        />
        <div style={{ height: 8 }} />

        {/* Cancel Order */}
        <FilledButton
          text={"Cancel Order"}
          backgroundColor={"#FF7900"}
          onPress={onCancel}
        />
        <div style={{ height: 8 }} />

        {/* Cancel Button */}
        <FilledButton
          text={"Cancel"}
          backgroundColor={"#FF4A49"}
          onPress={onClose}
        />
      </ModalContent>
    </Modal>
  );
};

export default OrderActionModal;
