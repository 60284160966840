import React from "react";

import { CgUnavailable } from "react-icons/cg";
import { FaRegCircleCheck } from "react-icons/fa6";
import {
  formatAvailableUntil,
  formatTime,
  getDayName,
} from "../helpers/DateHelpers";

const CommonMenuItem = ({ item, onNavigate, onChange }) => {
  return (
    <>
      <div className="border border-slate-300 flex flex-row justify-start items-center mb-2 rounded-xl">
        <img
          onClick={() => onNavigate(item)}
          className="h-24 w-22 rounded-l-xl object-cover"
          src={item?.image ?? ""}
        />
        <div
          onClick={() => onNavigate(item)}
          className="ml-3 py-2 flex flex-col justify-start items-start"
        >
          <h4 className="font-medium text-base">{item?.name ?? ""}</h4>

          {item?.availableDay ? (
            <h4 className="font-normal text-xs">
              Available on {getDayName(item?.availableDay)}
            </h4>
          ) : item?.unavailableUntil ? (
            <h4 className="font-normal text-[#FF4A49] text-xs">
              Unavailable {formatAvailableUntil(item?.unavailableUntil)}
            </h4>
          ) : (
            <h4 className="font-normal text-[#006400] text-xs">
              Always Available
            </h4>
          )}

          {item?.availableFrom ? (
            <h4 className="font-normal text-xs">
              Available from {formatTime(item?.availableFrom)}{" "}
              {item?.availableUntil
                ? `until ${formatTime(item?.availableUntil)}`
                : ""}
            </h4>
          ) : null}

          <h4 className="font-normal text-sm mt-1">
            IS: ${item?.instorePrice ?? ""} | P: ${item?.pickupPrice ?? ""} | D:
            ${item?.deliveryPrice ?? ""}
          </h4>
        </div>

        <div style={{ flex: 1 }} />
        {onChange ? (
          <div onChange={() => onChange(item)} className="mr-2">
            {item?.available ? (
              <CgUnavailable
                size={28}
                color="#FF4A49"
                onClick={() => {
                  console.log("Tapped Deactivate Item");
                }}
              />
            ) : (
              <FaRegCircleCheck
                size={22}
                color="#006400"
                onClick={() => {
                  console.log("Tapped Reactivate Item");
                }}
              />
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

const CommonMenuCategory = ({ item, onNavigate, onChangeStatus }) => {
  return (
    <>
      <div className="py-2 flex flex-row justify-start items-center mb-1 rounded-xl">
        <h4 className="font-medium">{item?.name ?? ""}</h4>
      </div>

      {item?.modifiers && item?.modifiers?.length > 0
        ? item?.modifiers?.map((item) => (
            <CommonMenuItem
              item={item}
              onNavigate={onNavigate}
              onChange={onChangeStatus}
            />
          ))
        : null}
    </>
  );
};

export default CommonMenuCategory;
