import React from "react";

import { CgUnavailable } from "react-icons/cg";
import { FaRegCircleCheck } from "react-icons/fa6";

const CommonModifierItem = ({ item, onChange }) => {
  return (
    <>
      <div className="border border-slate-300 flex flex-row justify-start items-center mb-2 rounded-xl">
        <div className="ml-3 py-2 flex flex-col justify-start items-start">
          <h4 className="font-medium text-base">{item?.name ?? ""}</h4>
        </div>

        <div style={{ flex: 1 }} />
        {onChange ? (
          <div onChange={() => onChange(item)} className="mr-2">
            {item?.available ? (
              <CgUnavailable
                size={28}
                color="#FF4A49"
                onClick={() => {
                  console.log("Tapped Deactivate Modifier");
                }}
              />
            ) : (
              <FaRegCircleCheck
                size={22}
                color="#006400"
                onClick={() => {
                  console.log("Tapped Reactivate Modifier");
                }}
              />
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

const CommonModifierGroup = ({ item, onChange }) => {
  return (
    <>
      <div className="py-2 flex flex-row justify-start items-center mb-1 rounded-xl">
        <h4 className="font-medium">{item?.group?.name ?? ""}</h4>
      </div>

      {item?.group?.options && item?.group?.options?.length > 0
        ? item?.group?.options?.map((option) => (
            <CommonModifierItem
              key={option?.id ?? ""}
              item={option}
              onChange={onChange}
            />
          ))
        : null}
    </>
  );
};

export default CommonModifierGroup;
