import React from "react";
import FilledButton from "../FilledButton";

const ReadyButtonGroup = ({ orderType, onPrint, onPickedUp, onActions }) => {
  return (
    <div className="grid grid-cols-1 gap-2">
      <div className="w-full">
        <FilledButton
          text={"Print Order"}
          backgroundColor={"#FA9235"}
          paddingVertical={10}
          onPress={onPrint}
        />
      </div>

      {orderType == "pickup" ? (
        <div className="w-full">
          <FilledButton
            text={"Mark as Picked Up"}
            backgroundColor={"#228B22"}
            paddingVertical={10}
            onPress={onPickedUp}
          />
        </div>
      ) : null}

      <div className="w-full">
        <FilledButton
          text={"Order Actions"}
          paddingVertical={10}
          backgroundColor={"#4166F5"}
          onPress={onActions}
        />
      </div>
    </div>
  );
};

export default ReadyButtonGroup;
