import React, { useState } from "react";

const DayDropdown = ({ onSelectDay }) => {
  const [selectedDay, setSelectedDay] = useState(""); // State to hold the selected day number

  // List of days with their corresponding day numbers
  const days = [
    { name: "Sunday", value: 0 },
    { name: "Monday", value: 1 },
    { name: "Tuesday", value: 2 },
    { name: "Wednesday", value: 3 },
    { name: "Thursday", value: 4 },
    { name: "Friday", value: 5 },
    { name: "Saturday", value: 6 },
  ];

  // Handler for when the dropdown value changes
  const handleChange = (event) => {
    setSelectedDay(event.target.value);
    onSelectDay(event.target.value);
  };

  return (
    <div>
      <select
        id="weekday-dropdown"
        value={selectedDay}
        className="w-full mt-2 border border-slate-300 py-3 px-2 bg-white rounded-lg"
        onChange={handleChange}
      >
        <option className="text-base font-medium">Select a day...</option>
        {days.map((day) => (
          <option key={day.value} value={day.value}>
            {day.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DayDropdown;
