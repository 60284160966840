import React from "react";

const CommonOptionSelector = ({
  options,
  value,
  onChange,
  paddingHorizontal = 16,
  marginBottom = 0,
}) => {
  if (!options || options.length <= 0) return;
  return (
    <>
      <div
        className="mt-2"
        style={{
          paddingHorizontal: paddingHorizontal,
          marginBottom: marginBottom,
        }}
      >
        <div
          className="rounded-full"
          style={{
            display: "flex",
            backgroundColor: "#CEC8C540",
            paddingHorizontal: 8,
            paddingVertical: 6,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {options && options?.length > 0
            ? options.map((option) => (
                <div
                  className="py-3 rounded-full"
                  key={option?.id}
                  style={{
                    backgroundColor:
                      value == option?.id ? "#55B836" : "transparent",
                    width: "49%",
                  }}
                  onClick={() => {
                    onChange(option?.id ?? 0);
                  }}
                >
                  <div>
                    <p
                      className="font-medium"
                      style={{
                        textAlign: "center",
                        color: value == option?.id ? "#fff" : "#000",
                      }}
                    >
                      {option?.name ?? ""}
                    </p>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export default CommonOptionSelector;
