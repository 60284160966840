import React, { useContext, useEffect, useState } from "react";
import CommonLoader from "../../../common/CommonLoader";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { RestaurantContext } from "../../../data/contexts/RestaurantContext";
import APIController from "../../../data/APIController";
import {
  GET_RESTAURANT_INTERFACES,
  UNLINK_RESTAURANT_INTERFACE,
} from "../../../constants/ApiEndpoints";
import FilledButton from "../../../common/FilledButton";
import CommonDeviceItem from "../../../common/CommonDeviceItem";

const AdditionalInterfaces = () => {
  const navigate = useNavigate();
  const { restaurant } = useContext(RestaurantContext);

  const [loading, setLoading] = useState(true);
  const [interfaces, setInterfaces] = useState([]);

  const getInterfaces = () => {
    setLoading(true);
    APIController.get(GET_RESTAURANT_INTERFACES + restaurant?.id ?? "")
      .then((response) => {
        console.log("INTERFACE RESPONSE: ", response.data);
        setInterfaces(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const unlinkInterface = (device) => {
    APIController.post(UNLINK_RESTAURANT_INTERFACE, {
      interfaceId: device?.id,
      restaurantId: restaurant?.id ?? "",
    })
      .then((response) => {
        console.log("INTERFACE UNLINK RESPONSE: ", response.data);
        getInterfaces();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getInterfaces();
  }, []);

  return (
    <>
      {loading ? (
        <div
          className="pt-12"
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CommonLoader width={128} height={128} />
          <p className="font-medium mt-2">Updating Interfaces</p>
        </div>
      ) : (
        <>
          <div className="flex flex-row fixed top-0 pt-6 pb-4 px-6 bg-white w-screen">
            <FaChevronLeft
              size={20}
              onClick={() => {
                navigate("/restaurant");
              }}
            />

            <div style={{ flex: 1 }} />
            <p className="text-base font-medium">Additional Interfaces</p>
            <div style={{ flex: 1 }} />

            <FaChevronLeft size={20} color={"#ffffff"} />
          </div>

          <div className="px-6 mt-20 pb-4">
            <h4 className="font-medium text-lg">Additional Interfaces</h4>
            <p className="font-normal text-sm mb-4">
              Additional Interfaces are add-on devices for your Appetite
              Restaurant Platform. This gives you a central point to connect &
              manage your OrderPads, Point of Sale Systems and any Kitchen
              Displays.
            </p>

            {interfaces.length > 0 ? (
              <>
                {interfaces?.map((device) => (
                  <CommonDeviceItem
                    key={device?.id ?? ""}
                    device={device}
                    onUnlink={unlinkInterface}
                  />
                ))}

                <div className="w-full mt-2">
                  <FilledButton
                    text={"Add Additional Interface"}
                    onPress={() => navigate("/settings/interfaces/add")}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className="pt-12"
                  style={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="font-medium mt-2">
                    You have not added any additional interfaces
                  </p>

                  <div className="px-6 w-full mt-2">
                    <FilledButton
                      text={"Add Additional Interface"}
                      onPress={() => navigate("/settings/interfaces/add")}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AdditionalInterfaces;
