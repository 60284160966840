import Lottie from "lottie-react";
import React from "react";

import loader from "../assets/animations/appetite_loading.json";

const CommonLoader = ({ width = 150, height = 150 }) => {
  return (
    <Lottie
      style={{ width: width, height: height }}
      animationData={loader}
      loop={true}
      autoPlay
    />
  );
};

export default CommonLoader;
