export const changeStatusBarColor = ({ type, color }) => {
  const message = JSON.stringify({
    type: "updateStatusBar",
    barType: type,
    barColor: color,
  });

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(message);
  }
};
