import React from "react";
import { useNavigate } from "react-router-dom";

const CommonIntegrationCard = ({
  integration,
  purchased,
  onTapPurchase,
  onTapConfigure,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="border border-slate-300 px-4 py-3 flex flex-col justify-start items-center mb-3 rounded-xl">
        <div className="flex flex-row justify-start items-center">
          <img
            src={integration?.logo ?? ""}
            className="w-12 h-12 object-contain rounded-lg"
          />
          <div className="ml-3 flex flex-col justify-start items-start">
            <h4 className="font-medium">{integration?.name ?? ""}</h4>
            <p className="font-normal text-sm mb-2">
              {integration?.description ?? ""}
            </p>
          </div>
        </div>
        <div
          onClick={() => {
            if (!purchased) return onTapPurchase(integration);
            return onTapConfigure(integration);
          }}
          className="w-full flex flex-row justify-center items-center px-4 rounded-full"
          style={{
            backgroundColor: "#55B836",
            paddingTop: 8,
            paddingBottom: 8,
            marginTop: 0,
          }}
        >
          <p className="font-medium" style={{ color: "#ffffff" }}>
            {purchased ? "Configure" : "Connect Integration"}
          </p>

          {!purchased ? <div style={{ flex: 1 }} /> : null}

          <p className="font-regular text-sm" style={{ color: "#ffffff" }}>
            {purchased
              ? ""
              : `$${
                  integration?.price
                    ? Number(integration?.price).toFixed(2)
                    : 0.0
                }/month`}
          </p>
        </div>
      </div>
    </>
  );
};
export default CommonIntegrationCard;
