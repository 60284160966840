import React from "react";
import { Input } from "@nextui-org/react";

const CommonTextField = ({
  placeholder,
  placeholderColor = "#000000",
  type = "text",
  value,
  onChange,
}) => {
  // Function to handle key press events
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Prevent form submission or any default action
      event.preventDefault();
      // Blur the input field to hide the keyboard
      event.target.blur();
    }
  };

  return (
    <>
      <p
        className="mb-2 text-lg font-normal"
        style={{ color: placeholderColor }}
      >
        {placeholder}
      </p>
      <Input
        className="font-medium rounded-sm"
        size={"lg"}
        type={type}
        placeholder={placeholder}
        onChange={(value) => onChange(value.target.value)}
        onKeyDown={handleKeyPress}
      />
    </>
  );
};

export default CommonTextField;
