import toast from "react-hot-toast";

export function showToastMessage(heading, message) {
  toast.custom(
    (t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } max-w-sm shadow-lg bg-[#55B836] rounded-xl pointer-events-auto flex`}
      >
        <div className="flex flex-col p-4">
          <div className="flex flex-col justify-center items-center px-4">
            <p className="text-sm text-center font-medium text-white">
              {heading}
            </p>
            <p className="mt-1 text-center text-sm text-white">{message}</p>
          </div>
        </div>
      </div>
    ),
    {
      duration: 2000,
    }
  );
}
