import React, { useState, useEffect } from "react";
import { Modal, ModalContent } from "@nextui-org/react";
import EventBus from "../../helpers/EventBus";
import FilledButton from "../FilledButton";

const BaseAppModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [title, setTitle] = useState("Default Title");
  const [content, setContent] = useState("Default Content");
  const [positiveButtonText, setPositiveButtonText] = useState(null);
  const [negativeButtonText, setNegativeButtonText] = useState(null);
  const [actionButtonText, setActionButtonText] = useState(null);

  const [positiveButtonTap, setPositiveButtonTap] = useState(() => {});
  const [negativeButtonTap, setNegativeButtonTap] = useState(() => {});
  const [actionButtonTap, setActionButtonTap] = useState(() => {});

  useEffect(() => {
    const showModal = (data) => {
      setTitle(data.title || "Default Title");
      setContent(data.description || "Default Content");
      setPositiveButtonText(data.positiveButtonText);
      setNegativeButtonText(data.negativeButtonText);
      setActionButtonText(data.actionButtonText);
      setPositiveButtonTap(() => data.positiveButtonTap); // Wrap function to ensure it's callable
      setNegativeButtonTap(() => data.negativeButtonTap); // Wrap function to ensure it's callable
      setActionButtonTap(() => data.actionButtonTap);
      setIsVisible(true);
    };

    const closeModal = () => {
      setIsVisible(false);
    };

    EventBus.on("openModal", showModal);
    EventBus.on("closeModal", closeModal);

    return () => {
      EventBus.removeListener("openModal", showModal);
      EventBus.removeListener("closeModal", closeModal);
    };
  }, []);

  const closeModal = () => {
    setIsVisible(false);
  };

  return (
    <Modal isOpen={isVisible} hideCloseButton={true} onOpenChange={closeModal}>
      <ModalContent
        className="px-6 py-3.5"
        style={{
          position: "fixed", // Fixed positioning to overlay content
          top: "50%", // Position at the middle of the viewport vertically
          left: "50%", // Position at the middle of the viewport horizontally
          transform: "translate(-50%, -50%)", // Adjust position to perfectly center
          width: "90%", // Set width to 90% of the viewport to have some margin on the sides
          maxWidth: "600px", // Limit max-width to 600px so it doesn't get too wide
          margin: "0 auto", // Center the modal horizontally
        }}
      >
        <h1 className="text-lg text-black mt-2 font-semibold">{title}</h1>
        <div className="mt-2 mb-2">
          <p className="text-base text-black font-normal mb-2">{content}</p>
        </div>

        {positiveButtonTap ? (
          <div className="mb-2">
            <FilledButton
              text={positiveButtonText}
              onPress={positiveButtonTap}
            />
          </div>
        ) : null}
      </ModalContent>
    </Modal>
  );
};

export default BaseAppModal;
