import React from "react";

const FilledButton = ({
  text,
  textColor = "#ffffff",
  backgroundColor = "#55B836",
  paddingVertical = 12,
  marginTop = 0,
  onPress,
}) => {
  return (
    <div
      onClick={onPress}
      className="w-full flex flex-row justify-center items-center px-4 rounded-full"
      style={{
        backgroundColor: backgroundColor,
        paddingTop: paddingVertical,
        marginTop: marginTop,
        paddingBottom: paddingVertical,
      }}
    >
      <p className="font-medium" style={{ color: textColor }}>
        {text}
      </p>
    </div>
  );
};

export default FilledButton;
