import React, { useContext, useEffect, useState } from "react";
import { RestaurantContext } from "../../../data/contexts/RestaurantContext";
import CommonLoader from "../../../common/CommonLoader";
import APIController from "../../../data/APIController";
import { GET_RESTAURANT_CAPABILITIES } from "../../../constants/ApiEndpoints";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CommonIntegrationCard from "../../../common/CommonIntegrationCard";
import PurchaseIntegrationModal from "../../../common/modals/PurchaseIntegrationModal";

const Integrations = () => {
  const navigate = useNavigate();
  const { restaurant } = useContext(RestaurantContext);

  const [loading, setLoading] = useState(true);
  const [integrations, setIntegrations] = useState([]);
  const [capabilities, setCapabilities] = useState({});

  const [selectedIntegration, setSelectedIntegration] = useState({});
  const [confirmIntegration, setConfirmIntegration] = useState(false);

  const getInterfaces = () => {
    setLoading(true);
    APIController.get(GET_RESTAURANT_CAPABILITIES + restaurant?.id ?? "")
      .then((response) => {
        console.log("INTEGRATIONS/CAPABILITIES RESPONSE: ", response.data);
        setIntegrations(response.data.data.integrations);
        setCapabilities(response.data.data.capabilities);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getInterfaces();
  }, []);

  return (
    <>
      <PurchaseIntegrationModal
        isVisible={confirmIntegration}
        onClose={() => setConfirmIntegration(!confirmIntegration)}
        integration={selectedIntegration ?? {}}
        onConfirm={() => {
          setConfirmIntegration(false);
          console.log("CONFIRMED INTEGRATION PRESSED");
        }}
      />
      {loading ? (
        <div
          className="pt-12"
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CommonLoader width={128} height={128} />
          <p className="font-medium mt-2">Updating Integrations</p>
        </div>
      ) : (
        <>
          <div className="flex flex-row fixed top-0 pt-6 pb-4 px-6 bg-white w-screen">
            <FaChevronLeft
              size={20}
              onClick={() => {
                navigate("/restaurant");
              }}
            />

            <div style={{ flex: 1 }} />
            <p className="text-base font-medium">Integration Settings</p>
            <div style={{ flex: 1 }} />

            <FaChevronLeft size={20} color={"#ffffff"} />
          </div>

          <div className="px-6 mt-20 pb-4">
            <h4 className="font-medium text-lg">Integration Settings</h4>
            <p className="font-normal text-sm mb-4">
              Manage your Integrations or connect an available Integration to
              your store.
            </p>

            {/* Tyro Integration Card */}
            <CommonIntegrationCard
              purchased={capabilities?.tyro ?? false}
              integration={integrations?.find((value) =>
                value.name.includes("Tyro")
              )}
              onTapConfigure={(integration) => {
                console.log(
                  `Tapped Configure for Integration: ${integration?.name ?? ""}`
                );
              }}
            />

            {/* Appetite Bookings Integration Card */}
            {integrations?.find((value) =>
              value.name.includes("Appetite Bookings")
            ) ? (
              <CommonIntegrationCard
                purchased={capabilities?.tableBooking ?? false}
                integration={integrations?.find((value) =>
                  value.name.includes("Appetite Bookings")
                )}
                onTapPurchase={(integration) => {
                  setSelectedIntegration(integration);
                  setConfirmIntegration(true);
                }}
                onTapConfigure={(integration) => {
                  console.log(
                    `Tapped Configure for Integration: ${
                      integration?.name ?? ""
                    }`
                  );
                }}
              />
            ) : null}

            {/* DoorDash Drive Integration Card */}
            {integrations?.find((value) =>
              value.name.includes("DoorDash Drive")
            ) ? (
              <CommonIntegrationCard
                purchased={capabilities?.doordashDrive ?? false}
                integration={integrations?.find((value) =>
                  value.name.includes("DoorDash Drive")
                )}
                onTapPurchase={(integration) => {
                  setSelectedIntegration(integration);
                  setConfirmIntegration(true);
                }}
                onTapConfigure={(integration) => {
                  console.log(
                    `Tapped Configure for Integration: ${
                      integration?.name ?? ""
                    }`
                  );
                }}
              />
            ) : null}

            {/* Uber Direct Integration Card */}
            {integrations?.find((value) =>
              value.name.includes("Uber Direct")
            ) ? (
              <CommonIntegrationCard
                purchased={capabilities?.uberDirect ?? false}
                integration={integrations?.find((value) =>
                  value.name.includes("Uber Direct")
                )}
                onTapPurchase={(integration) => {
                  setSelectedIntegration(integration);
                  setConfirmIntegration(true);
                }}
                onTapConfigure={(integration) => {
                  console.log(
                    `Tapped Configure for Integration: ${
                      integration?.name ?? ""
                    }`
                  );
                }}
              />
            ) : null}

            {/* Deliverect Integration Card */}
            {integrations?.find((value) =>
              value.name.includes("Deliverect")
            ) ? (
              <CommonIntegrationCard
                purchased={capabilities?.deliverect ?? false}
                integration={integrations?.find((value) =>
                  value.name.includes("Deliverect")
                )}
                onTapPurchase={(integration) => {
                  setSelectedIntegration(integration);
                  setConfirmIntegration(true);
                }}
                onTapConfigure={(integration) => {
                  console.log(
                    `Tapped Configure for Integration: ${
                      integration?.name ?? ""
                    }`
                  );
                }}
              />
            ) : null}

            {/* Square Integration Card */}
            {integrations?.find((value) => value.name.includes("Square")) ? (
              <CommonIntegrationCard
                purchased={capabilities?.square ?? false}
                integration={integrations?.find((value) =>
                  value.name.includes("Square")
                )}
                onTapPurchase={(integration) => {
                  setSelectedIntegration(integration);
                  setConfirmIntegration(true);
                }}
                onTapConfigure={(integration) => {
                  console.log(
                    `Tapped Configure for Integration: ${
                      integration?.name ?? ""
                    }`
                  );
                }}
              />
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default Integrations;
