import React from "react";
import { FaRegClock } from "react-icons/fa";
import { formatTime } from "../helpers/DateHelpers";
import { MdDeleteOutline } from "react-icons/md";
import { IoPencil } from "react-icons/io5";

const CommonHourItem = ({ hour, onDelete }) => {
  return (
    <>
      <div className="border border-slate-300 px-4 py-2 flex flex-row justify-center items-center mb-3 rounded-xl">
        <FaRegClock size={20} />

        <div className="ml-3 flex flex-col justify-start items-start">
          <h4 className="font-medium mb-1">{hour?.dayName ?? ""}</h4>

          {hour?.timeslots
            ? hour?.timeslots?.map((timeslot) => (
                <h4 className="mb-1 text-sm font-medium">
                  <span className="font-semibold">Open:</span>{" "}
                  {formatTime(timeslot?.openTime)} -{" "}
                  <span className="font-semibold">Close:</span>{" "}
                  {formatTime(timeslot?.closeTime)}
                </h4>
              ))
            : null}
        </div>

        <div style={{ flex: 1 }} />

        {/* <IoPencil
          size={24}
          style={{ marginRight: 8 }}
          onClick={() => alert("Tapped Edit Trading Hour")}
        /> */}

        <MdDeleteOutline
          size={28}
          color="#FF4A49"
          onClick={() => onDelete(hour)}
        />
      </div>
    </>
  );
};

export default CommonHourItem;
