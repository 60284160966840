import React, { useEffect, useState } from "react";
import { Modal, ModalContent } from "@nextui-org/react";
import FilledButton from "../FilledButton";

import { TbCirclePlus, TbCircleMinus } from "react-icons/tb";

import { ring2 } from "ldrs";

ring2.register();

const DelayOrderModal = ({ isVisible, isDelaying, onClose, onSubmit }) => {
  const [minutes, setMinutes] = useState(10);

  const increaseMinutes = () => {
    setMinutes((currentMinutes) => currentMinutes + 5);
  };

  const decreaseMinutes = () => {
    if (minutes > 10) {
      setMinutes((currentMinutes) => currentMinutes - 5);
    }
  };

  useEffect(() => {
    setMinutes(10);
  }, [isVisible]);

  return (
    <Modal isOpen={isVisible} hideCloseButton={true} onOpenChange={onClose}>
      <ModalContent
        className="px-6 pt-3.5 pb-6"
        style={{
          position: "fixed", // Fixed positioning to overlay content
          top: "50%", // Position at the middle of the viewport vertically
          left: "50%", // Position at the middle of the viewport horizontally
          transform: "translate(-50%, -50%)", // Adjust position to perfectly center
          width: "90%", // Set width to 90% of the viewport to have some margin on the sides
          maxWidth: "600px", // Limit max-width to 600px so it doesn't get too wide
          margin: "0 auto", // Center the modal horizontally
          overlay: "auto",
          zIndex: 1000,
        }}
      >
        <h1 className="text-lg text-black mt-2 font-semibold">Delay Order</h1>
        <div className="mt-2 mb-2">
          <p className="text-base text-black font-normal mb-2">
            Select how long to delay this order for
          </p>
        </div>

        <div className="flex flex-row justify-center items-center mb-6">
          <TbCircleMinus size={38} onClick={decreaseMinutes} />

          <div style={{ flex: 1 }} />

          <h4 className="font-semibold text-2xl text-black">
            {minutes} minutes
          </h4>

          <div style={{ flex: 1 }} />

          <TbCirclePlus size={38} onClick={increaseMinutes} />
        </div>

        {/* Delay Order */}
        <div className="w-full items-center flex justify-center">
          {!isDelaying ? (
            <>
              <div className="w-full flex flex-col">
                <FilledButton
                  text={"Delay Order"}
                  backgroundColor={"#228B22"}
                  onPress={() => onSubmit(minutes)}
                />
                <FilledButton
                  text={"Cancel"}
                  marginTop={8}
                  backgroundColor={"#FF4A49"}
                  onPress={onClose}
                />
              </div>
            </>
          ) : (
            <>
              <l-ring-2
                size="40"
                stroke="5"
                stroke-length="0.25"
                bg-opacity="0.1"
                speed="0.8"
                color="#55B836"
              ></l-ring-2>
            </>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default DelayOrderModal;
