import React, { useContext, useEffect, useState } from "react";
import APIController from "../../data/APIController";
import {
  GET_ACTIVE_ORDERS,
  GET_PAST_ORDERS,
  GET_RESTAURANT_NOTICES,
} from "../../constants/ApiEndpoints";
import { RestaurantContext } from "../../data/contexts/RestaurantContext";
import { showAPIError } from "../../helpers/FieldHelpers";
import CommonLoader from "../../common/CommonLoader";
import CommonTypeSelector from "../../common/CommonTypeSelector";
import CommonOrderItem from "../../common/CommonOrderItem";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const { restaurant } = useContext(RestaurantContext);

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  const [notices, setNotices] = useState([]);
  const [noticeIndex, setNoticeIndex] = useState(0);

  const [currentIndex, setCurrentIndex] = useState(0);
  const orderTypes = [
    {
      id: 0,
      name: "Active",
    },
    {
      id: 1,
      name: "Past",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      const index = Math.floor(Math.random() * notices.length);
      console.log("New Notice Index: ", index);
      setNoticeIndex(index);
    }, 10000);
  }, [notices, noticeIndex]);

  const getActiveOrders = () => {
    setLoading(true);
    APIController.get(`${GET_ACTIVE_ORDERS}${restaurant?.id ?? ""}`)
      .then((response) => {
        console.log("Orders Response: ", response);

        if (response.data.success == false) {
          showAPIError(response.data.message);
          return;
        }

        setOrders(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Order Error: ", error);
      });
  };

  const getPastOrders = () => {
    setLoading(true);
    APIController.get(`${GET_PAST_ORDERS}${restaurant?.id ?? ""}`)
      .then((response) => {
        console.log("Orders Response: ", response);

        if (response.data.success == false) {
          showAPIError(response.data.message);
          return;
        }

        setOrders(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Order Error: ", error);
      });
  };

  const getRestaurantUpdates = () => {
    APIController.get(GET_RESTAURANT_NOTICES)
      .then((response) => {
        console.log("Restaurant Notice Response: ", response);

        if (response.data.success == false) {
          showAPIError(response.data.message);
          return;
        }

        setNotices(response.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 250);
      })
      .catch((error) => {
        console.log("Order Error: ", error);
      });
  };

  useEffect(() => {
    if (restaurant?.id) {
      getActiveOrders();
    }
    getRestaurantUpdates();
  }, []);

  useEffect(() => {
    if (restaurant?.id) {
      if (currentIndex == 0) {
        getActiveOrders();
      } else {
        getPastOrders();
      }
    }
  }, [currentIndex]);

  return (
    <>
      {loading ? (
        <div
          className="pt-12"
          style={{
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CommonLoader width={128} height={128} />
          <p className="font-medium mt-2">Updating Orders</p>
        </div>
      ) : (
        <>
          <div className="mt-24 pb-24">
            <CommonTypeSelector
              options={orderTypes}
              value={currentIndex}
              onChange={setCurrentIndex}
            />

            {orders.length > 0 ? (
              orders?.map((order, index) => (
                <CommonOrderItem key={order?.id ?? index} order={order} />
              ))
            ) : (
              <div
                style={{
                  height: "70vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="object-contain w-[140px] h-[120px]"
                  alt={notices[noticeIndex]?.name ?? ""}
                  src={notices[noticeIndex]?.image ?? ""}
                />
                <p className="font-semibold mt-4 text-lg">
                  {notices[noticeIndex]?.name ?? ""}
                </p>

                <p className="font-normal mt-2 px-8 text-center text-base">
                  {notices[noticeIndex]?.description ?? ""}
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Orders;
