import React, { useState } from "react";

function CustomCheckbox({ label, checked, onChange }) {
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckboxChange = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <div className="flex flex-row items-center justify-center">
      <label className="flex flex-row items-center justify-center">
        <input
          type="checkbox"
          className="sr-only" // sr-only hides the checkbox visually but remains accessible
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <div
          className={`w-8 h-8 inline-block mr-2 flex flex-row justify-center items-center rounded-lg ${
            isChecked ? "bg-appetite" : "bg-gray-200"
          }`}
        >
          {isChecked && (
            <svg
              className="fill-current text-white w-6 h-6 block mx-auto my-auto"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
            </svg>
          )}
        </div>
        {label && <span className="text-black font-medium">{label}</span>}
      </label>
    </div>
  );
}

export default CustomCheckbox;
