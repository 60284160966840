import { useState, useEffect } from "react";
import { RestaurantContext } from "../contexts/RestaurantContext";

export const RestaurantProvider = ({ children }) => {
  const [restaurant, setRestaurant] = useState(() => {
    // Initialize state with data from localStorage
    if (typeof window !== "undefined") {
      const savedData = localStorage.getItem("restaurantData");
      return savedData ? JSON.parse(savedData) : {};
    }
    return {};
  });

  useEffect(() => {
    // This effect will run on every render
    const savedData = localStorage.getItem("restaurantData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      // Only update state if the data is different
      if (JSON.stringify(parsedData) !== JSON.stringify(restaurant)) {
        setRestaurant(parsedData);
      }
    }
  }, []); // Add restaurant to the dependency array

  useEffect(() => {
    // Save to localStorage whenever the restaurant state changes
    localStorage.setItem("restaurantData", JSON.stringify(restaurant));
  }, [restaurant]);

  return (
    <RestaurantContext.Provider value={{ restaurant, setRestaurant }}>
      {children}
    </RestaurantContext.Provider>
  );
};
