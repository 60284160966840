import React, { useContext, useEffect, useState } from "react";
import { RestaurantContext } from "../../data/contexts/RestaurantContext";
import {
  showAPIError,
  validatePassword,
  validateUsername,
} from "../../helpers/FieldHelpers";
import APIController from "../../data/APIController";
import {
  GET_RESTAURANT_PROFILE,
  LOGIN_RESTAURANT,
} from "../../constants/ApiEndpoints";
import CommonTextField from "../../common/CommonTextField";
import FilledButton from "../../common/FilledButton";
import { closePopup, openPopup } from "../../helpers/ModalHelpers";
import { useNavigate } from "react-router-dom";
import { changeStatusBarColor } from "../../data/device/DeviceActions";

const Login = () => {
  const navigate = useNavigate();
  const { restaurant, setRestaurant } = useContext(RestaurantContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    changeStatusBarColor({ type: "light-content", color: "#181818" });
  }, []);

  const loginRestaurant = () => {
    validateUsername(username);
    validatePassword(password);

    APIController.post(LOGIN_RESTAURANT, {
      email: username,
      password: password,
    })
      .then((response) => {
        console.log("Login Response: ", response.data);

        if (response.data.success == false) {
          showAPIError(response.data?.message ?? "");
          return;
        }

        localStorage.setItem("accessToken", response.data.data.access_token);
        setTimeout(() => {
          getRestaurantProfile();
        }, 250);
      })
      .catch((error) => {
        console.log("Error Logging in Restaurant", error);
      });
  };

  const getRestaurantProfile = () => {
    APIController.get(GET_RESTAURANT_PROFILE)
      .then((response) => {
        console.log("Restaurant Profile Response: ", response);

        if (response.data.success == false) {
          showAPIError(response.data.message);
          localStorage.clear();
          //   router.push("/login");
        }

        setRestaurant(response.data.data);
        setTimeout(() => {
          navigate("/restaurant");
        }, 250);
      })
      .catch((error) => {
        console.log("Error finding restaurant profile", error);
      });
  };

  return (
    <>
      <div className="w-full h-full bg-[#181818]">
        <div>
          {/* <img
            alt={"Login"}
            className="w-full h-full absolute top-0 bottom-0 left-0 right-0 contain"
            src={require("../../assets/images/ic_login_bg.webp")}
          /> */}

          <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-t from-[#181818] to-[#181818] opacity-100 z-20" />

          {/* Overlayed Content */}
          <div className="w-full h-full flex flex-col justify-center items-center absolute top-0 bottom-0 left-0 right-0 z-30">
            <img
              alt={"Appetite Point of Sale"}
              className="contain w-32 h-32"
              src={require("../../assets/images/ic_appetite_logo.webp")}
            />

            <div className="w-full px-8 mt-6">
              <h1 className="text-white text-center text-xl font-bold">
                Appetite Point of Sale
              </h1>
              <h4 className="text-white text-center text-base mt-2">
                Login to your Appetite Restaurant Account
              </h4>
            </div>

            {/* Username */}
            <div className="mt-6 w-full px-8">
              <CommonTextField
                placeholder={"Username"}
                placeholderColor="#ffffff"
                value={username}
                onChange={setUsername}
              />
            </div>

            <div className="mt-4 w-full px-8">
              <CommonTextField
                placeholder={"Password"}
                placeholderColor="#ffffff"
                type={"password"}
                value={password}
                onChange={setPassword}
              />
            </div>

            <div className="mt-6 w-full px-8">
              <FilledButton
                text={"Login"}
                onPress={() => {
                  loginRestaurant();
                }}
              />
            </div>

            <div
              onClick={() => {
                openPopup({
                  title: "Forgot your login details?",
                  description:
                    "No worries! You can either view your tablet login details from your Restaurant Control App, or you can give our team a call to retrieve your login details",
                  positiveButtonTap: () => {
                    closePopup();
                  },
                  positiveButtonText: "Okay",
                });
              }}
              className="relative flex flex-row justify-center items-center pt-8"
            >
              <p className="text-white font-medium text-lg">
                Forgot your login details?
              </p>
              <p className="ml-1 text-white text-lg font-bold">Tap here</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
