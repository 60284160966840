import React, { useEffect, useState } from "react";
import { Howl } from "howler";

const PlaySound = ({ shouldPlay }) => {
  const [sound, setSound] = useState(null);

  useEffect(() => {
    // Initialize the sound
    const newSound = new Howl({
      src: [require("../assets/sounds/appetite_new_order.wav")],
      loop: true, // Enable looping
    });
    setSound(newSound);

    // Clean up the sound when the component unmounts
    return () => {
      if (newSound) {
        newSound.unload();
      }
    };
  }, []);

  useEffect(() => {
    if (sound) {
      if (shouldPlay) {
        sound.play();
      } else {
        sound.stop();
      }
    }
  }, [shouldPlay, sound]);

  return (
    <div>
      {/* Optionally, you can add controls to manually start and stop the sound */}
    </div>
  );
};

export default PlaySound;
