import React from "react";
import { Modal, ModalContent } from "@nextui-org/react";
import FilledButton from "../FilledButton";

const PurchaseIntegrationModal = ({
  isVisible,
  onClose,
  integration,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isVisible} hideCloseButton={true} onOpenChange={onClose}>
      <ModalContent
        className="px-6 pt-3.5 pb-6"
        style={{
          position: "fixed", // Fixed positioning to overlay content
          top: "50%", // Position at the middle of the viewport vertically
          left: "50%", // Position at the middle of the viewport horizontally
          transform: "translate(-50%, -50%)", // Adjust position to perfectly center
          width: "90%", // Set width to 90% of the viewport to have some margin on the sides
          maxWidth: "600px", // Limit max-width to 600px so it doesn't get too wide
          margin: "0 auto", // Center the modal horizontally
          overlay: "auto",
          zIndex: 1000,
        }}
      >
        <h1 className="text-lg text-black mt-2 font-semibold">
          Confirm Integration Purchase
        </h1>
        <div className="mt-2 mb-2">
          <p className="text-base text-black font-normal mb-2">
            Please confirm you intend to add-on the {integration?.name ?? ""}{" "}
            Integration to your Appetite Restaurant Account.
          </p>

          <p className="text-base text-black font-normal mb-2">
            {integration?.name ?? ""} is charged at $
            {integration?.price ? Number(integration?.price).toFixed(2) : ""}{" "}
            AUD per month. This will be charged with your upcoming Monthly
            Direct Debit, and is pro-rated for usage prior to your monthly
            direct debit date.
          </p>
        </div>

        {/* Confirm Button */}
        <FilledButton
          text={"Confirm Integration Purchase"}
          onPress={onConfirm}
        />

        {/* Cancel Button */}
        <FilledButton
          marginTop={8}
          text={"Cancel"}
          backgroundColor={"#FF4A49"}
          onPress={onClose}
        />
      </ModalContent>
    </Modal>
  );
};

export default PurchaseIntegrationModal;
