export const getOrderColor = (status) => {
  var color = "#000000";

  switch (status) {
    case "scheduled":
      color = "#FA9235";
      break;
    case "awaiting":
      color = "#55B836";
      break;
    case "preparing":
      color = "#006692";
      break;
    case "store_enroute":
      color = "#FFC324";
      break;
    case "customer_enroute":
      color = "#FFC324";
      break;
    case "ready":
      color = "#710193";
      break;
    case "picked_up":
      color = "#006692";
      break;
    case "delivered":
      color = "#006692";
      break;
    case "declined":
      color = "#FF4A49";
      break;
    case "refunded":
      color = "#FF4A49";
      break;
    default:
      color = "#ffffff";
      break;
  }

  return color;
};

export const getOrderTextColor = (status) => {
  var color = "#ffffff";

  return color;
};

export const getFormattedOrderStatus = (status) => {
  var text = "";

  switch (status) {
    case "scheduled":
      text = "Order Scheduled";
      break;
    case "awaiting":
      text = "Awaiting Acceptance";
      break;
    case "preparing":
      text = "Preparing Order";
      break;
    case "store_enroute":
      text = "Driver Enroute to Store";
      break;
    case "customer_enroute":
      text = "Driver Enroute to Customer";
      break;
    case "ready":
      text = "Order Ready to Collect";
      break;
    case "picked_up":
      text = "Order Picked Up";
      break;
    case "delivered":
      text = "Order Delivered";
      break;
    case "declined":
      text = "Order Declined";
      break;
    case "refunded":
      text = "Order Fully Refunded";
      break;
    case "partial_refunded":
      text = "Partially Refunded";
      break;
    default:
      text = "Awaiting Acceptance";
      break;
  }

  return text;
};
