import React, { useContext, useEffect, useState } from "react";
import CommonLoader from "../../../common/CommonLoader";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import APIController from "../../../data/APIController";
import {
  DELETE_TRADING_HOURS,
  GET_RESTAURANT_HOURS,
} from "../../../constants/ApiEndpoints";
import { RestaurantContext } from "../../../data/contexts/RestaurantContext";
import { showAPIError } from "../../../helpers/FieldHelpers";
import CommonHourItem from "../../../common/CommonHourItem";
import FilledButton from "../../../common/FilledButton";

const TradingHours = () => {
  const navigate = useNavigate();
  const { restaurant } = useContext(RestaurantContext);

  const [loading, setLoading] = useState(true);
  const [hours, setHours] = useState([]);

  const getTradingHours = () => {
    setLoading(true);
    APIController.get(GET_RESTAURANT_HOURS + restaurant?.id ?? "")
      .then((response) => {
        console.log("Found Restaurant Hours: ", response.data.data);

        if (!response.data.success) {
          showAPIError(response.data?.message ?? "");
          navigate(-1);
        }

        setHours(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error Fetching Trading Hours: ", error);
      });
  };

  const deleteTradingHours = (hour) => {
    APIController.patch(DELETE_TRADING_HOURS, {
      restaurantId: restaurant?.id ?? "",
      hourId: hour?.id ?? "",
    })
      .then((response) => {
        console.log("Delete Trading Hours Response: ", response);

        if (!response.data.success) {
          showAPIError(response.data?.message ?? "");
          return;
        }

        getTradingHours();
      })
      .catch((error) => {
        console.log("Error Deleting Trading Hours");
        showAPIError(error?.message ?? "");
        return;
      });
  };

  useEffect(() => {
    getTradingHours();
  }, []);

  return (
    <>
      {loading ? (
        <div
          className="pt-12"
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CommonLoader width={128} height={128} />
          <p className="font-medium mt-2">Updating Trading Hours</p>
        </div>
      ) : (
        <>
          <div className="flex flex-row fixed top-0 pt-6 pb-4 px-6 bg-white w-screen">
            <FaChevronLeft
              size={20}
              onClick={() => {
                navigate("/restaurant");
              }}
            />

            <div style={{ flex: 1 }} />
            <p className="text-base font-medium">Trading Hours</p>
            <div style={{ flex: 1 }} />

            <FaChevronLeft size={20} color={"#ffffff"} />
          </div>

          <div className="px-6 mt-20 pb-4">
            {hours.length > 0
              ? hours?.map((hour) => (
                  <CommonHourItem
                    key={hour?.id ?? ""}
                    hour={hour}
                    onDelete={deleteTradingHours}
                  />
                ))
              : null}

            <div className="w-full mt-2">
              <FilledButton
                text={"Add Trading Hours"}
                onPress={() => navigate("/settings/hours/add")}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TradingHours;
