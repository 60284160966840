export const toSentenceCase = (text) => {
  if (!text) return "";

  const sentences = text.match(/[^\.!\?]+[\.!\?]+/g);

  if (!sentences) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  const casedSentences = sentences.map(
    (sentence) =>
      sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase()
  );

  return casedSentences.join(" ");
};
