import React from "react";
import FilledButton from "../FilledButton";

import { ring2 } from "ldrs";

ring2.register();

const AwaitingButtonGroup = ({
  onAccept,
  onReject,
  acceptingOrder,
  rejectingOrder,
}) => {
  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="w-full items-center flex justify-center">
        {!acceptingOrder ? (
          <FilledButton
            text={"Accept Order"}
            paddingVertical={10}
            onPress={onAccept}
          />
        ) : (
          <>
            <l-ring-2
              size="40"
              stroke="5"
              stroke-length="0.25"
              bg-opacity="0.1"
              speed="0.8"
              color="#55B836"
            ></l-ring-2>
          </>
        )}
      </div>

      <div className="w-full items-center flex justify-center">
        {!rejectingOrder ? (
          <FilledButton
            text={"Decline Order"}
            paddingVertical={10}
            backgroundColor={"#FF4A49"}
            onPress={onReject}
          />
        ) : (
          <>
            <l-ring-2
              size="40"
              stroke="5"
              stroke-length="0.25"
              bg-opacity="0.1"
              speed="0.8"
              color="#FF4A49"
            ></l-ring-2>
          </>
        )}
      </div>
    </div>
  );
};

export default AwaitingButtonGroup;
