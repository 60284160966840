import React, { useContext, useEffect, useState } from "react";

import {
  IoReceiptOutline,
  IoReceiptSharp,
  IoStorefrontOutline,
  IoStorefrontSharp,
} from "react-icons/io5";
import { PiBowlFood, PiBowlFoodFill } from "react-icons/pi";
import { MdOutlineTableBar, MdTableBar } from "react-icons/md";
import { RestaurantContext } from "../../data/contexts/RestaurantContext";

const BottomNavigationBar = ({ currentTab, changeTab }) => {
  const { restaurant } = useContext(RestaurantContext);
  return (
    <>
      <div className="fixed left-0 right-0 bottom-0 py-4 rounded-t-2xl w-full bg-appetite">
        <div className="flex flex-row px-8">
          {/* Orders */}
          <div onClick={() => changeTab(0)}>
            {currentTab == 0 ? (
              <div className="bg-white p-1 rounded-lg">
                <IoReceiptSharp className="p-1 text-4xl text-[#55B836]" />
              </div>
            ) : (
              <div className="p-1">
                <IoReceiptOutline className="text-4xl text-white" />
              </div>
            )}
          </div>

          <div style={{ flex: 1 }} />

          {/* Bookings */}
          {restaurant?.capabilities &&
          restaurant?.capabilities?.tableBooking ? (
            <>
              <div onClick={() => changeTab(1)}>
                {currentTab == 1 ? (
                  <div className="bg-white p-1 rounded-lg">
                    <MdTableBar className="p-0.5 text-4xl text-[#55B836]" />
                  </div>
                ) : (
                  <div className="p-1">
                    <MdOutlineTableBar className="text-4xl text-white" />
                  </div>
                )}
              </div>

              <div style={{ flex: 1 }} />
            </>
          ) : null}

          {/* Menu */}
          <div onClick={() => changeTab(2)}>
            {currentTab == 2 ? (
              <div className="bg-white p-1 rounded-lg">
                <PiBowlFoodFill className="p-0.5 text-4xl text-[#55B836]" />
              </div>
            ) : (
              <div className="p-1">
                <PiBowlFood className="text-4xl text-white" />
              </div>
            )}
          </div>

          <div style={{ flex: 1 }} />

          {/* Profile */}
          <div onClick={() => changeTab(3)}>
            {currentTab == 3 ? (
              <div className="bg-white p-1 rounded-lg">
                <IoStorefrontSharp className="p-1 text-4xl text-[#55B836]" />
              </div>
            ) : (
              <div className="p-1">
                <IoStorefrontOutline className="text-4xl text-white" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomNavigationBar;
