import { FaChevronRight } from "react-icons/fa";

const SettingsItem = ({ backgroundColor, icon, name, onPress }) => {
  return (
    <>
      <div onClick={onPress} className="mt-2 px-0 py-0 w-full rounded-xl mb-5">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className={`w-12 h-12 rounded-xl flex justify-center items-center`}
            style={{ backgroundColor: backgroundColor }}
          >
            {icon}
          </div>

          <p className="ml-3 font-medium text-base">{name}</p>
          <div style={{ flex: 1 }} />
          <FaChevronRight className="mr-1" />
        </div>
      </div>
    </>
  );
};

export default SettingsItem;
