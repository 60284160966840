import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RestaurantProvider } from "./data/providers/RestaurantProvider";
import { BrowserRouter } from "react-router-dom";

import { NextUIProvider } from "@nextui-org/react";
import BaseAppModal from "./common/modals/BaseAppModal";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <NextUIProvider>
      <RestaurantProvider>
        <BrowserRouter>
          <App />
          <BaseAppModal />
        </BrowserRouter>
      </RestaurantProvider>
    </NextUIProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
