import moment from "moment";
import React, { useState } from "react";
import { formatTime } from "../helpers/DateHelpers";

const TimeDropdown = ({ disabled = false, onSelectTime }) => {
  const [selectedTime, setSelectedTime] = useState(""); // State to hold the selected time

  // Generate time options
  const generateTimeOptions = (increment) => {
    const times = []; // Array to hold time options
    let hour, minute;

    for (hour = 0; hour < 24; hour++) {
      // Loop through each hour of the day
      for (minute = 0; minute < 60; minute += increment) {
        // Loop through each minute increment within the hour
        // Format hour and minute to be two digits
        const hourFormatted = hour.toString().padStart(2, "0");
        const minuteFormatted = minute.toString().padStart(2, "0");
        times.push({
          value: `${hourFormatted}:${minuteFormatted}`,
          display: formatTime(`${hourFormatted}:${minuteFormatted}`),
        }); // Add time to the list
      }
    }

    return times;
  };

  // List of times in 30-minute increments
  const timeOptions = generateTimeOptions(15);

  // Handler for when the dropdown value changes
  const handleChange = (event) => {
    setSelectedTime(event.target.value);
    onSelectTime(event.target.value);
  };

  return (
    <div>
      <select
        id="time-dropdown"
        disabled={disabled}
        value={selectedTime}
        onChange={handleChange}
        className="w-full mt-2 border border-slate-300 py-3 px-2 bg-white rounded-lg"
      >
        <option value="">Select a time...</option>
        {timeOptions.map((time) => (
          <option key={time.value} value={time.display}>
            {time.display}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimeDropdown;
