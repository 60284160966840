import React, { useEffect, useState } from "react";
import { Input } from "@nextui-org/react";
import { Modal, ModalContent } from "@nextui-org/react";
import FilledButton from "../FilledButton";

import { ring2 } from "ldrs";

ring2.register();

const RefundAmountModal = ({ isVisible, onClose, onRefund, refunding }) => {
  const [amount, setAmount] = useState(null);

  useEffect(() => {
    if (!isVisible) {
      setAmount(null);
      return;
    }
  }, [isVisible]);

  return (
    <Modal
      isOpen={isVisible}
      hideCloseButton={true}
      onOpenChange={() => {
        onClose();
        setAmount(null);
      }}
    >
      <ModalContent
        className="px-6 pt-3.5 pb-6"
        style={{
          position: "fixed", // Fixed positioning to overlay content
          top: "50%", // Position at the middle of the viewport vertically
          left: "50%", // Position at the middle of the viewport horizontally
          transform: "translate(-50%, -50%)", // Adjust position to perfectly center
          width: "90%", // Set width to 90% of the viewport to have some margin on the sides
          maxWidth: "600px", // Limit max-width to 600px so it doesn't get too wide
          margin: "0 auto", // Center the modal horizontally
          overlay: "auto",
          zIndex: 1000,
        }}
      >
        <h1 className="text-lg text-black mt-2 font-semibold">
          Refund Specific Amount
        </h1>
        <div className="mt-2 mb-2">
          <p className="text-base text-black font-normal mb-2">
            Please enter the amount you'd like to refund for this order
          </p>
        </div>

        <Input
          type="number"
          label="Refund Amount"
          placeholder="0.00"
          labelPlacement="outside"
          value={amount}
          onChange={(value) => setAmount(value.target.value)}
          startContent={
            <div className="pointer-events-none flex items-center">
              <span className="text-default-400 text-small">$</span>
            </div>
          }
        />

        {/* Refund Button */}
        {!refunding ? (
          <FilledButton
            marginTop={16}
            text={`Refund \$${Number(amount).toFixed(2)}`}
            onPress={() => onRefund(amount)}
          />
        ) : (
          <div className="mt-4 w-full items-center flex justify-center">
            <l-ring-2
              size="40"
              stroke="5"
              stroke-length="0.25"
              bg-opacity="0.1"
              speed="0.8"
              color="#55B836"
            ></l-ring-2>
          </div>
        )}

        {/* Cancel Button */}
        <FilledButton
          marginTop={8}
          text={"Cancel"}
          backgroundColor={"#FF4A49"}
          onPress={() => {
            onClose();
            setAmount(null);
          }}
        />
      </ModalContent>
    </Modal>
  );
};

export default RefundAmountModal;
